import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
require('./lib/js-cookie-min');

import * as LocomotiveScroll from './lib/locomotive-scroll-min';  //'./lib/locomotive-scroll-min';
import * as Cookies from './lib/js-cookie-min';
  
$("body").css({"overflow":"hidden"});

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
(function($, window, document) {
    $(function() {
        console.log("Haptik 1.3");
        
        
        let arrServ = "#svEstrategiaDigital, #svFotografia, #svVideoContent, #svMotionGraphics";
        let xDymServ = "#svEstrategiaDigital .service-row--detail, #svFotografia .service-row--detail, #svVideoContent .service-row--detail, #svMotionGraphics .service-row--detail";
        
        let btns = ['eDigital', 'eFoto', 'eContent', 'eMotion'];
        let aServices = [
            {
                id:'#svEstrategiaDigital',
                num: ""
            },
            {
                id:'#svFotografia',
                num: ""
            },
            {
                id:'#svVideoContent',
                num: ""
            },
            {
                id:'#svMotionGraphics',
                num: ""
            }
        ];

        let isServOpen = false;
        let lastOpen = "";

        const APIPath = 'https://api-sa-east-1.graphcms.com/v2/cl5cab3zl0jop01ulg2n375h7/master';

        // fetch(APIPath, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //     },
        //     body: JSON.stringify({
        //         query: `
        //             query {
        //               products {
        //                 id
        //                 name
        //                 price
    
        //               }
        //             }
        //             `,
        //       }),
        //     }
        // ).then((res) => {
        //     if (!res.ok) return Promise.reject(response);
    
        //     return res.json();
        // }).then((res) => {
        //     const { products } = res.data;
        //     const productsList = document.querySelector('ul#products');
              
        //     products.map((product) => {
        //         const li = document.createElement('li');    
        //         li.textContent = product.name;    
        //         productsList.appendChild(li);
        //     });
        // });

        /* Creating an array of objects. */
        let aProjects = [
            {
                id:"FIGHTERFIT",
                vid:"709627827",
                type:"VIDEO",
                pimage:"assets/img/static/PP-Gym.jpg",
                name:"FIGHTER FIT",
                description:"<p>El año 2021, fue un periodo donde el sector del deporte aún se encontraba debilitado como consecuencia de la crisis originada por la covid-19, eran muchas las personas que aún sentían temor por salir de sus casas y regresar a un gimnasio era una decisión difícil de tomar.</p><p>Teniendo en cuenta estos insights, nuestros amigos de Fighter Fit quisieron promocionar su gimnasio de una manera diferente y este fue el resultado, que sin locución logra transmitir la pasión que encierra el volver a un gimnasio.</p><p>Dirección: Alejandro Chang<br>Producción: Haptik<br>Post Producción: Haptik<br>Musicalización: Steve Goulden</p>"
            },
            {
                id:"PERKINS",
                vid:"691028125",
                type:"VIDEO",
                pimage:"assets/img/static/pp-Perkins.jpg",
                name:"PERKINS",
                description:"<p>Perkins es una marca peruana de ginger ale, que se utiliza mayormente para acompañar destilados.</p><p>En esta oportunidad, elaboramos para ellos, un contenido audiovisual que buscaba transmitir de una manera dinámica y real, la manera en la que este producto puede ser utilizado y finalmente dar a conocer, la variedad de presentaciones que tiene.</p><p>Dirección: Alejandro Chang<br>Post Producción: Alejandro Chang</p>"
            },
            {
                id:"PABOGADOS",
                vid:"709628068",
                type:"VIDEO",
                pimage:"assets/img/static/pp-Abogados.jpg",
                name:"PALACIOS & ABOGADOS",
                description:"<p>Un video corporativo no tiene porque siempre seguir la misma estructura de quienes somos, misión, visión y valores, Por ello, junto a Palacios & Abogados, decidimos realizar una presentación diferente de su estudio, iniciando por el contexto actual post pandemia y el cómo ellos participan de los procesos legales de sus clientes, siendo cercanos, reales y transparentes.</p><p>La difusión de este video institucional logró incrementar el 25% de las asistencias legales que la empresa realizaba.</p><p>Dirección: Alejandro Chang<br>Producción: Haptik<br>Post Producción: Haptik<br>Musicalización: Steve Goulden</p>"
            },
            {
                id:"AQUACOM",
                vid:"713378078",
                type:"VIDEO",
                pimage:"assets/img/static/pp-Aquacom.jpg",
                name:"AQUACOM SYSTEM",
                description:"<p>Aquacom Systems es una empresa dedicada a crear, fabricar y distribuir maquinarias para el tratamiento de agua de mesa.</p> <p>Para ellos, elaboramos tres videos publicitarios de sus productos estrellas, resaltando que todos estos son creados originalmente por ellos.</p> <p>En este primer video de la máquina “Triblock de llenado para bidones”, se realizó un increíble trabajo de post producción, ya que se tenía que lograr un impacto sin locución en off u on, sino únicamente a través de imágenes, animaciones y musicalización.</p><p>Dirección: Alejandro Chang<br>Producción: Haptik<br>Post Producción: Haptik<br>Musicalización: Haptik</p>"
            },
            {
                id:"IMOCert",
                vid:"709636173",
                type:"MOTION GRAPHICS",
                pimage:"assets/img/static/pp-IMOCert.jpg",
                name:"CERTIFICADO ORGÁNICO - IMOCERT",
                description:"<p>IMOcert es una empresa dedicada a la certificación de productos orgánicos y consideraban un problema el cómo comunicar a sus clientes sus procesos de trabajo.</p><p>Como solución, en Haptik decidimos realizar junto a ellos, un motion graphic de 50 segundos, en donde logramos explicar de manera rápida y sencilla, el proceso que debía seguir una persona para obtener su certificación orgánica.</p><p>Al ser un proyecto corto y de fácil entendimiento, el contenido audiovisual pudo ser compartido en redes sociales y ferias donde IMOcert tuvo presencia.</p><p>Guión: Haptik<br>Ilustración: Haptik<br>Animación: Ana Lombardi<br>Musicalización: Steve Goulden</p>"
            },
            {
                id:"JDIn",
                vid:"709627976",
                type:"VIDEO",
                pimage:"assets/img/static/pp-JDGrupo.jpg",
                name:"JD INMOBILIARIA",
                description:"<p>JD Inmobiliaria es una empresa que brinda asesoría legal para proyectos inmobiliarios. Ellos buscaban incentivar su posicionamiento, para lo cual requirieron la elaboración de un video corporativo corto, que explicara sus servicios y mostrara a parte de su equipo.</p><p>Dirección: Alejandro Chang<br>Producción: Haptik<br>Post Producción: Haptik<br>Musicalización: Steve Goulden</p>"
            },
            {
                id:"APPAGUA",
                vid:"709637844",
                type:"MOTION GRAPHICS",
                pimage:"assets/img/static/pp-AppAgua.jpg",
                name:"APP TODO SOBBRE EL AGUA",
                description:"<p>Como parte de una campaña de difusión, Todo Sobre Agua nos convocó para ayudarles a desarrollar un video promocional sencillo pero impactante.</p><p>Por tal motivo, decidimos utilizar la técnica del motion graphic, para así comunicar en 20 segundos, como funcionaba la app, que podías encontrar dentro de ella y cuál era el beneficio de realizar compras ahí.</p><p>Guión: Haptik<br>Ilustración: Haptik<br>Animación: Ana Lombardi<br>Musicalización: Haptik</p>"
            },
            {
                id:"INVERSIONES",
                vid:"709627589",
                type:"VIDEO",
                pimage:"assets/img/static/pp-Agua.jpg",
                name:"MÁQUINA TRIBLOCK",
                description:"<p>Nuestros amigos de IDP buscaban incentivar la compra de su nueva máquina triblock, una máquina creada específicamente para impulsar a emprendedores a iniciar con su propio negocio en el rubro de tratamiento de agua.</p><p>En Haptik sabíamos desde un inicio que este proyecto sería un reto, ya que la máquina contaba con muchas partes y funcionalidades. Por eso, decidimos crear una campaña pequeña, pero de alta calidad, en donde utilizamos un kit completo de iluminación para generar dinamismo y además resaltar todas aquellas partes del equipo que eran imperceptibles a primera vista.</p><p>Otro aspecto importante de este video promocional, fue contar con un actor, que con su técnica y tono de voz, ayudó a crear una sensación de mayor cercanía con el target.</p><p>Dirección: Alejandro Chang<br>Producción: Haptik<br>Post Producción: Haptik<br>Musicalización: Haptik</p>"
            },
        ];    

        let sProject = "";
        const objReveal = {
            'data-animation-in' : "fade-in"
        }
        let ppReveal = new Foundation.Reveal($("#ppModal"), objReveal);
        const getProjects = () => {
            aProjects.forEach((project, index) => {
                let sProj = `#${project.id}`;
                sProject += `<!-- ${project.id} -->
                <figure id="${project.id}" class="project" vid="${project.vid}" data-scroll data-scroll-speed="1" data-scroll-class="appear" data-scroll-repeat="true">
                    <img src="${project.pimage}" >
                </figure>`;

                

                $(".gallery-projects").on('click', sProj, () => {
                    // $("#ppModal").foundation('_disableScroll');
                    $("body").css({"overflow":"hidden"});
                    $("[vimeo-api]").html(`<style>.container{width: 100%;min-height: 500px;display: flex;justify-content: center;align-items: center;flex-direction: column;} .logo-black-w{ filter: invert(100%) sepia(0%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);} </style>
                    <div class="container">
                    <figure>
                      <object type="image/svg+xml" data="assets/img/ui/logo-black.svg" class="logo-black-w" width="250">
                          Haptik 
                      </object>
                    </figure>
                  </div>`);

                    console.log("this" + project.vid);
                    let vid = project.vid; //this.attr("vid");
                    $("[project-name]").html(project.name);
                    $("[project-type]").html(project.type);
                    $("[Project-info]").html(`<h4 class='fontArea'>${project.name}</h4>${project.description}`);

                    $("[vimeo-api]").html(`<div class="video-embeded-container"><iframe src="https://player.vimeo.com/video/${vid}?h=c4a945f7ed&title=0&byline=0&portrait=0&autoplay=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`);
                    // $.getJSON('https://www.vimeo.com/api/oembed.json?url=' + encodeURIComponent('https://vimeo.com/'+vid) + '&title=0&byline=0&portrait=0&autoplay=0', function(data){
                    //     $("[vimeo-api]").html(`<div class="video-embeded-container">${data.html}</div><script src="https://player.vimeo.com/api/player.js"></script>`);
                    //     // <iframe src="https://player.vimeo.com/video/709628068?h=8f6b0a6afa&title=0&byline=0&portrait=0&autoplay=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
                    //     // console.log("data:"+data.html);
                        
                    // });

                    ppReveal.open();
                });

                $("#ppModal").on("closed.zf.reveal", (ev,form) => {
                    //  console.log({ev});
                    $("body").css({"overflow":"visible"});
                    // $("#ppModal").foundation('_enableScroll');
                    $("[vimeo-api]").html(`<style>.container{width: 100%;min-height: 500px;display: flex;justify-content: center;align-items: center;flex-direction: column;} .logo-white{ filter: invert(100%) sepia(0%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);} </style>
                    <div class="container">
                    <figure>
                      <object type="image/svg+xml" data="assets/img/ui/logo-black.svg" class="logo-white" width="250">
                          Haptik 
                      </object>
                    </figure>
                  </div>`);
                });
            });

            $("[gallery-projects]").html(sProject);
        };

        getProjects();    

        /**
         * It initializes the LocomotiveScroll object, and it adds event listeners to the services, and
         * it adds event listeners to the window
         * @returns The initApp function is being returned.
         */
        const initApp = () => {
            $("body").css({"overflow":"visible"});

            const locoScroll = new LocomotiveScroll({
                el: document.querySelector('[data-scroll-container]'),
                smooth: true,
                repeat: true,
                getDirection: true,
                reloadOnContextChange: true,
                smartphone: {
                    // smooth: true,
                }
            }); 

            $("section.preloading").slideUp(1000, function() {
                resized();
            });

            $('#mProjects, #mServices, #mTeam, #mTalk').on('click', function() {
                var anchor = $('#'+this.id).attr('data-scroll-anchor');
                locoScroll.scrollTo('#'+anchor, {
                    'offset': 5
                });
                
                let close = debounce(function () {
                    $('#offCanvas').foundation('close');
                }, 500);
                
                close();            

                return false;
            });

            $('#fixEBook div figure').slideUp();
            // let appHeight = $('#AppContainer').height();
            let isOpen = false;
            locoScroll.on('call', (value, way, obj) => {
                // console.log({obj});
                // console.log({way});
                if (value === "fixEBook") {
                    // let currMQ = Foundation.MediaQuery.upTo('medium');

                    if(way === "enter" && !isOpen){
                        // console.log('fixEBook');
                        $('#fixEBook div figure').slideDown(1300, () => {
                            isOpen = true;
                            resized();
                        });
                        
                    }else if(way === "exit" && isOpen){
                        // console.log('fixEBook');
                        $('#fixEBook div figure').slideUp(1300, () => {
                            // console.log('EXIT');
                            resized();
                            isOpen = false;
                        });
                        
                        
                    }
                }
            });

            locoScroll.on('scroll', (instance) => {
                document.documentElement.setAttribute('data-direction', instance.direction);
            });

            ///// REST SITE
            let overPic = false;
            let overTeam = false;
            // let sTeam = '#AServelon, #GRafael, #GPonce, #BMunoz, #AChang';
            $('div.team-row').hover((ev) => {
                overTeam = true;
                $('[pic-team]').html(`<img src="assets/img/static/${ev.currentTarget.attributes[2].value}.jpg">`);
                $("#PicTeam").show().hover((ev) => {
                    overPic = true;
                }, (e) => {
                    $("#PicTeam").hide();
                    overPic = false;
                });     
            });

            $('div.hover-chek').on("mouseleave", (ev) => {
                // console.log("out");
                setTimeout(() => {
                    if(!overPic) $("#PicTeam").hide();
                }, 1000);
                
            });

            /**
             * It removes the class 'open' from the array of services, slides up the dynamic service
             * content, and resets the number of the service that was clicked
             */
            const closeServices = () => {
                
                $(xDymServ).slideUp(300, function(){
                    $(arrServ).removeClass('noHover');
                    $(arrServ).removeClass('open');
                });
                isServOpen = false;
            }

            /**
             * It loops through the array of objects, and for each object, it finds the element with
             * the id of the object, and then it finds the element with the class of service-count, and
             * then it replaces the html of that element with the number of the object
             */
            const resetNum = () => {
                aServices.forEach((serv, index) => {
                    // console.log(serv.id );
                    $(serv.id + " .service-count").html(`<h3>${serv.num}</h3>`);
                });
            }

            

            /**
             * It removes the class 'open' from all elements in the array arrServ, slides up all
             * elements in the array xDymServ, slides down the element with the class
             * 'service-row--detail' that is a child of the element with the id 'id', adds the class
             * 'open' to the element with the id 'id', sets the variable isServOpen to the value of the
             * variable servOpn, and sets the variable lastOpen to the value of the variable lastOpn
             * @param id - the id of the service that is being clicked on
             * @param lastOpn - the last open service
             * @param [servOpn=true] - is a boolean that determines whether the service is open or not.
             */
            const serviceMod = (id, lastOpn, servOpn = true) => {
                $(arrServ).removeClass('open');
                $(xDymServ).slideUp(300);
                $(id +" .service-row--detail").slideDown(500); 
                $(id).addClass('open');
                isServOpen = servOpn;
                lastOpen = lastOpn;    
            }

            // SERVICIOS
            $("#svEstrategiaDigital").on('click', (ev) => {
                let cases = 3;
                let id = "#svEstrategiaDigital";

                $(arrServ).addClass('noHover');
                if(isServOpen && lastOpen === 'eDigital') {
                    closeServices();
                    resized();
                    // $("#svEstrategiaDigital .service-count").html(`<h3>${cases}</h3>`);
                }else{

                    $(arrServ).removeClass('open');
                    $("#svEstrategiaDigital").addClass('open');
                    $(xDymServ).slideUp(300);
                    
                    $("#svEstrategiaDigital .service-row--detail").slideDown(500, function(){
                        $(arrServ).removeClass('noHover');
                        
                        
                    }); 
                    
                    isServOpen = true;
                    lastOpen = "eDigital";
                    resized();
                }
            });

            $("#svFotografia").on('click', () => {
                $(arrServ).addClass('noHover');
                if(isServOpen && lastOpen === 'eFoto') {
                    closeServices();
                    resized();
                    // $("#svEstrategiaDigital .service-count").html(`<h3>${cases}</h3>`);
                }else{
                    // resetNum();
                    
                    $(arrServ).removeClass('open');
                    $("#svFotografia").addClass('open');
                    $(xDymServ).slideUp(300);

                    $("#svFotografia .service-row--detail").slideDown(500, function(){
                        $(arrServ).removeClass('noHover');
                        
                        // $("#svFotografia .service-count").html(`<figure id="eFoto" class="serv-btn-play" data-open="revealFoto">
                        //     <object type="image/svg+xml" data="assets/img/ui/play-me-btn.svg" width="35">
                        //         Play cases
                        //     </object>                                    
                        // </figure>`);
                        
                    }); 
                    
                    isServOpen = true;
                    lastOpen = "eFoto";
                    resized();
                }
            });

            $("#svVideoContent").on('click', () => {
                $(arrServ).addClass('noHover');
                if(isServOpen && lastOpen === 'eContent') {
                    closeServices();
                    resized();
                    
                }else{
                    // resetNum();                    
                    $(arrServ).removeClass('open');
                    $("#svVideoContent").addClass('open');
                    $(xDymServ).slideUp(300);
                    
                    $("#svVideoContent .service-row--detail").slideDown(500, function(){
                        $(arrServ).removeClass('noHover');
                        
                        // $("#svVideoContent .service-count").html(`<figure id="eContent" class="serv-btn-play" data-open="revealContent">
                        //     <object type="image/svg+xml" data="assets/img/ui/play-me-btn.svg" width="35">
                        //         Play cases
                        //     </object>                                    
                        // </figure>`);
                        
                    });
                    
                    isServOpen = true;
                    lastOpen = "eContent";
                    resized();
                }
            });

            $("#svMotionGraphics").on('click', () => {
                $(arrServ).addClass('noHover');
                if(isServOpen && lastOpen === 'eMotion') {
                    closeServices();
                    resized();
                }else{
                    // resetNum();
                    $(arrServ).removeClass('open');
                    $("#svMotionGraphics").addClass('open');
                    $(xDymServ).slideUp(300);

                    $("#svMotionGraphics .service-row--detail").slideDown(500, function(){
                        $(arrServ).removeClass('noHover');
                        
                        // $("#svMotionGraphics .service-count").html(`<figure id="eMotion" class="serv-btn-play" data-open="revealMotion">
                        //     <object id type="image/svg+xml" data="assets/img/ui/play-me-btn.svg" width="35">
                        //         Play cases
                        //     </object>
                        // </figure>`);
                    });
                    
                    
                    isServOpen = true;
                    lastOpen = "eMotion";
                    resized();
                }
            });
            
            // $("#jsFormCotizar,#jsFormCotizar1,#jsFormCotizar2").on("formvalid.zf.abide", (ev,form) => {

            // let left = $('#EBook').offset().left;  // Get the calculated left position
            // $("#EBook").css({left:'-100%'}); //.animate({"left":"0px"}, "slow");
            $("#BookDelMes").on('click', () => {
                let mqSmall = Foundation.MediaQuery.is('small down');
                if(mqSmall){
                    // window.location.href = "/assets/MotionGraphics-Haptik.pdf";
                    window.open("/assets/MotionGraphics-Haptik.pdf", "_blank");
                }else{
                    $(".ebook-float-banner").toggleClass('show');
                }
            });

            
            
            

            /**
             * It closes all select boxes in the document, except the current select box
             * @param elmnt - The element that was clicked.
             */
            const closeAllSelect = (elmnt) => {
                var x, y, i, xl, yl, arrNo = [];
                x = document.getElementsByClassName("select-items");
                y = document.getElementsByClassName("select-selected");
                xl = x.length;
                yl = y.length;
                for (i = 0; i < yl; i++) {
                    if (elmnt == y[i]) {
                        arrNo.push(i)
                    } else {
                        y[i].classList.remove("select-arrow-active");
                    }
                }
                for (i = 0; i < xl; i++) {
                    if (arrNo.indexOf(i)) {
                        x[i].classList.add("select-hide");
                    }
                }
            }

            /* If the user clicks anywhere outside the select box,
            then close all select boxes: */
            document.addEventListener("click", closeAllSelect);

            $('.project-carrousel').slick({
                dots: true,
                arrows: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 2000,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                // centerPadding: '50px',
                lazyLoad: 'progressive',
                fade: false
            });

            $('.project-carrousel-v').slick({
                dots: true,
                arrows: false,
                infinite: true,
                autoplaySpeed: 2000,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                vertical: true,
                verticalSwiping: true,
                adaptiveHeight:true,
                // variableHeight:false
                // responsive
            });   


            /**
             * The debounce function takes a function and a timeout as arguments, and returns a new
             * function that will call the original function after the timeout has elapsed.
             * @param func - The function to be called after the timeout.
             * @param [timeout=300] - The time in milliseconds to wait before executing the function.
             * @returns A function that takes in a function and a timeout. The function that is passed
             * in is called after the timeout.
             */
            const debounce = (func, timeout = 300) => {
                let timer;
                return (...args) => {
                  clearTimeout(timer);
                  timer = setTimeout(() => { func.apply(this, args); }, timeout);
                };
            }
    
            /* The above code is creating a debounced function that will update the locoScroll object. */
            let resized = debounce(function () {
                if (locoScroll) {
                    locoScroll.update();
                    // console.log("update");
                }
            }, 250);
            
            /* Calling the resized function when the window is resized. */
            $(window).on("resize", resized);



            /* The constructor takes three arguments: 
            
            el: the element to be animated
            toRotate: the text to be animated
            period: the length of the animation
            
            The tick function is called every time the animation is to be updated. 
            
            The tick function takes no arguments. 
            
            The tick function updates the text in the element by adding a character or deleting a
            character from the text. 
            
            The tick function also checks to see if the text has reached the end of the text to be
            animated. 
            
            If the text has reached the end of the text to be animated, the tick function sets the
            isDeleting flag to true. 
            
            If the text has reached the beginning of the text to be animated, the tick function sets the
            isDeleting flag to false. 
            
            The tick function also sets the animation delay to a random number between 0 and 100. 
            
            The tick function also checks to */
            class txtRotate {
                constructor(el, toRotate, period, size) {
                    this.toRotate = toRotate;
                    this.el = el;
                    this.loopNum = 0;
                    this.period = parseInt(period, 10) || 1000;
                    this.toSize = size;
                    this.size = '';
                    this.txt = '';
                    this.tick();
                    this.isDeleting = false;
                }

                tick() {
                    var i = this.loopNum % this.toRotate.length;
                    var fullTxt = this.toRotate[i];
                    var fullSize = this.toSize[i]; 

                    if (this.isDeleting) {
                        this.txt = fullTxt.substring(0, this.txt.length - 1);
                        // this.size = fullTxt.substring(0, this.txt.length - 1);
                    } else {
                        this.txt = fullTxt.substring(0, this.txt.length + 1);
                        // this.size = fullSize.substring(0, this.txt.length + 1);
                    }

                    this.el.innerHTML = '<span class="wrap">' + this.txt + '\xa0</span>';
                    // console.log(this.txt);

                    var that = this;
                    var delta = 150 - Math.random() * 100;

                    if (this.isDeleting) { delta /= 2; }

                    if (!this.isDeleting && this.txt === fullTxt) {
                        delta = this.period;
                        // console.log('> '+fullTxt);
                        // console.log('> '+fullSize);
                        
                        // $(".gif-rotate").attr('src','assets/img/static/'+fullTxt+'-welcome.gif');
                        // width="450" style="width: '+fullSize+'px;"
                        $("[data-gif-rotate]").html('<img src="assets/img/static/'+fullTxt+'-wlcm.gif" width="450" style="width: '+fullSize+'"></img>');
                        $("[data-gif-rotate]").animate({
                            marginLeft: '0',
                            width:fullSize
                        });
                        // document.getElementsByClassName('gif-rotate')[0].src = 'assets/img/static/'+fullTxt+'-welcome.gif';
                        // placeholder.innerHTML = `<img class="mini-reel" src="assets/img/static/${fullTxt}-welcome.gif" width="450" style="width: 450px;" ></img>`;
                        this.isDeleting = true;
                    } else if (this.isDeleting && this.txt === '') {
                        this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';
                        $("[data-gif-rotate]").animate({
                            marginLeft: '0',
                            width:'100%'
                        });
                        this.isDeleting = false;
                        this.loopNum++;
                        delta = 500;
                    }

                    setTimeout(function () {
                        that.tick();
                    }, delta);
                }
            }
            
            /* A way to create a new object of type txtRotate for each element with the class txt-rotate. */
            var elements = document.getElementsByClassName('txt-rotate');
            for (var u=0; u<elements.length; u++) {
                var toRotate = elements[u].getAttribute('data-rotate');
                var period = elements[u].getAttribute('data-period');
                var toSize = elements[u].getAttribute('data-rotate-size');
                if (toRotate) {
                    new txtRotate(elements[u], JSON.parse(toRotate), period, JSON.parse(toSize));
                }
            }

            // INJECT CSS
            // let css = document.createElement("style");
            // css.type = "text/css";
            // css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #FFF }";
            // document.body.appendChild(css);       
            
            // CUSTOM SELECT / FORM
            let x, i, j, l, ll, selElmnt, a, b, c;
            /* Look for any elements with the class "custom-select": */
            x = document.getElementsByClassName("custom-select");
            l = x.length;
            for (i = 0; i < l; i++) {
                selElmnt = x[i].getElementsByTagName("select")[0];
                ll = selElmnt.length;
                /* For each element, create a new DIV that will act as the selected item: */
                a = document.createElement("DIV");
                a.setAttribute("class", "select-selected");
                a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
                x[i].appendChild(a);
                /* For each element, create a new DIV that will contain the option list: */
                b = document.createElement("DIV");
                b.setAttribute("class", "select-items select-hide");
                for (j = 1; j < ll; j++) {
                    /* For each option in the original select element,
                    create a new DIV that will act as an option item: */
                    c = document.createElement("DIV");
                    c.innerHTML = selElmnt.options[j].innerHTML;
                    c.addEventListener("click", function(e) {
                        /* When an item is clicked, update the original select box,
                        and the selected item: */
                        var y, i, k, s, h, sl, yl;
                        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                        sl = s.length;
                        h = this.parentNode.previousSibling;
                        for (i = 0; i < sl; i++) {
                        if (s.options[i].innerHTML == this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = this.innerHTML;
                            y = this.parentNode.getElementsByClassName("same-as-selected");
                            yl = y.length;
                            for (k = 0; k < yl; k++) {
                                y[k].removeAttribute("class");
                            }
                            this.setAttribute("class", "same-as-selected");
                            break;
                        }
                        }
                        h.click();
                    });
                    b.appendChild(c);
                }

                x[i].appendChild(b);
                a.addEventListener("click", function(e) {
                    /* When the select box is clicked, close any other select boxes,
                    and open/close the current select box: */
                    e.stopPropagation();
                    closeAllSelect(this);
                    this.nextSibling.classList.toggle("select-hide");
                    this.classList.toggle("select-arrow-active");
                });
            }   


            $("#ContactUs").on("formvalid.zf.abide", function(ev,form) { 
                // form validation passed, form will submit if submit event not returned false
                console.log("Form id "+form.attr('id')+" is valid");
                // ajax post form
                onContactSubmit('contactus.php',form.attr('id'));
            }).on('submit', function(event){
                event.preventDefault();
                // $("#jsContactForm,#jsContactFormDk,#jsContactFormInd").foundation('resetForm');
            });

            /**
             * It sends the form data to the server.
             * @param script - The URL to the script that will be called.
             * @param id - The ID of the form.
             */
            const onContactSubmit = (script,id) => {
                let formID = "#"+id;
                let name = $(formID+" #FullName").val();  
                
                $("[response-server]").html("");

                $.post(script, $(formID).serialize(), function(data){
                    $(".form-group").removeClass("has-error");
                    $(".help-block").remove();

                    if(!data.success) {
                        if (data.errors.fullname) {
                            // $("#contactNameGroup").after('<div class="help-block has-error">' + data.errors.fullname + '</div>');
                        }
                
                        if (data.errors.email) {
                            // $("#contactEmailGroup").after('<div class="help-block has-error">' + data.errors.email + '</div>');
                        }
                
                        // if (data.errors.industry) {
                        //     $("#contactIndustryGroup").after('<div class="help-block has-error">' + data.errors.industry + '</div>');
                        // }
                        
                        if (data.errors.message) {
                            // $("#contactServiceGroup").after('<div class="help-block has-error">' + data.errors.message + '</div>');
                        }

                        if (data.errors.service) {
                            // $("#contactServiceGroup").after('<div class="help-block has-error">' + data.errors.service + '</div>');
                        }
    
                        if (data.errors.checkpp) {
                            $("#validpp-group").after('<div class="help-block has-error">' + data.errors.checkpp + '</div>');
                        }

                        resized();

                    }else{
                        $("[response-server]").html('<div class="help-block label error" style="white-space: break-spaces;">'+ data.message +'</div>');

                        resized();
                    }

                }).fail(function() { 
                    // style="font-size: 1.5rem; line-height: 2rem; white-space: break-spaces;"
                    $("[response-server]").html('<div class="help-block label error" style="white-space: break-spaces;">El mensaje no se pudo enviar. Intente nuevamente o llamenos al <b>(+51) 934331966</b></div>');
                    
                    // setTimeout(() => {
                    //     $("[response-server]").html(`<input type="submit" class="button" value="enviar">`);
                    // }, 10000);
                    
                    resized();
                });
            };

        };


        /*  */
        // let scrollContainer = document.querySelector("[data-scroll-container]");
        // $("[data-scroll-container]").imagesLoaded({ background: true }).progress((instance, image) => {
        $("#Projects, #Services, #fixEBook, #Team, #Talk").imagesLoaded({ background: true }, () => {
            initApp(); 
        });

    });
}(window.jQuery, window, document));

$(document).foundation();
